//import scss file
@import 'variables';
@import 'mixins';

@import "hero";
@import "header";
@import "country-select";

// Load fonts
@include font-face('Lato', '../public/fonts/lato/Lato-Regular');

//You can write css or scss
body {
  display: flex;
  align-items: center;
  // height: 100vh;
  // width: 100vw;
  // width: 100%;
  flex-direction: column;
  padding: 1rem;
  font-family: $font-default, sans-serif;
  background-color: $color-grey;
  color: black;
  margin: 0;
  padding: 0;
}

body * {
  -webkit-font-smoothing: antialiased;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: $color-grey;
  width: 100%;
  padding: 64px 0;

  @include target(md, lg) {
    padding: 120px 0;
  } 
}

.container {

  @include target(lg) {
    width: 840px;
    margin-left: auto;
    margin-right: auto;
  }
}

.overlay {
  display: none;
  position: fixed;
  background-color: transparent;
  width: 100%;
  height: 100vh;
  z-index: 950;
  transition: background-color 0.3s ease;

  &.visible {
    display: block;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

