@use "sass:math";

.country-select {
  margin-left: $spacing-standard-sm;
  margin-right: $spacing-standard-sm;
  
  @include target(md) {
    margin-left: calc(2 * $spacing-standard-sm);
    margin-right: calc(2 * $spacing-standard-sm);
  }

  @include target(lg) {
    margin-left: auto;
    margin-right: auto;
    width: 840px;
  }

  ul {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.15);
    padding: 0px math.div($spacing-standard-sm, 2);
    margin: 0;
    display: flex;
    flex-direction: column;

    @include target(md, lg) {
      padding: 0px 16px;
    }
  }

  li {
    padding: 0;
    list-style: none;
    border-bottom: 1px solid #E4E4E4;
    position: relative;

    &:last-child {
      border-bottom: none;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $spacing-standard-sm;
      color: black;
      text-decoration: none;
      padding: $spacing-standard-sm 8px;
      position: relative;
      z-index:5;
      font-weight: 700;

      @include target(md, lg) {
        padding: 26px 16px;
      }

      &::after {
        content: url('../public/arrow-right.svg');
        position: absolute;
        right: 8px;
        top: 30px;
        
        @include target(md, lg) {
          top: 40px;
          right:16px;
        }
      }

      span {
        position: relative;
        top: 2px;
      }
    }
  }
}