/* Colors */
$color-grey: #f5f5f5;
$color-language-links: rgba(0, 0, 0, 0.60);

/* Fonts */
$font-default: 'Lato';
$font-size-default: 16px;

/* Headings */
$h1-size-sm: 24px;
$h1-size-lg: 48px;

/* Spacing */
$spacing-standard-sm: 16px;

/* Breakpoint configuration */
$breakpoints: (
  // Breakpoint Definition
  sm: 0,
  // Small screen / phone
  md: 600px,
  // Medium Screen Tablet
  lg: 1240px // Large Screen / Desktop
);