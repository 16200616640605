.hero {
  position: relative;
  background-image: url('../public/header.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 232px;
  width: 100%;

  @include target(md, lg) {
    height: 400px;
    background-size: cover;
  }

  .hero-text {
    position: absolute;
    z-index: 100;
    bottom: 0;
    background-image: url('../public/hero-text-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: calc(100% - calc(2 * $spacing-standard-sm));
    height: 138px;
    padding: 0 $spacing-standard-sm;
    
    @include target(md, lg) {
      width: 422px;
      height: 269px;
      padding: 0;
      background-position-y: 8px;
    }

    @include target(md) {
      margin-left: calc(2 * $spacing-standard-sm);
    }
    
    h3 {
      margin-top: 48px;
      color: white;
      font-size: $h1-size-sm;
      font-family: $font-default;
      font-weight: 700;

      @include target(sm) {
        width: 200px;
        margin-left: 0;
      }

      @include target(md) {
        margin-left: 40px;
      }

      @include target(md, lg) {
        font-size: $h1-size-lg;
        margin-top: 90px;
        margin-right: 60px;
      }
      
      @include target(lg) {
        margin-left: 40px;
      }
    }

  }
}