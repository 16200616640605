header {
  width: calc(100% - calc(2 * $spacing-standard-sm));
  padding: 0 16px;
  background-color: white;
  
  .container {
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;

    @include target(lg) {
      height: 71px;
    }
  }
  
  .logo {
    width: 127px;
    height: 17px;
  }


  .open-menu-button {
    padding: 0;
    border: 0;
    background: transparent;
    width: 22px;
    height: 14.667px;
    margin-left: auto;
    cursor: pointer;

    @include target(lg) {
      display: none;
    }

    img {
      width: 22px;
      height: 14.667px;
    }

  }

  nav.mobile-nav {
    @include target(lg) {
      display: none;
      visibility: hidden;
    }
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -256px;
    z-index: 1000;
    width: 256px;
    height: 100vh;
    background-color: white;
    transition: right 0.2s ease;

    &.opened {
      right: 0;
    }

    .close-menu-button {
      padding-top: 21px;
      border: 0;
      background: transparent;
      width: 14px;
      height: 14px;
      margin-left: auto;
      margin-right: 24px;
      margin-bottom: $spacing-standard-sm;
      cursor: pointer;

      img {
        width: 14px;
        height: 14px;
      }
    }
  
    .language-changer__inner {
      border-top: 1px solid rgba(0 ,0,0, 0.1);
      margin-top: 15px;
      
      ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        width: 216px;
        gap: 24px;
        margin-left: 20px;
        margin-top: 15px;
        padding: 0;
        
        li {
          display: flex;
          color: $color-language-links;
          font-family: $font-default;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
  
          a {
            color: rgba(0, 0, 0, 0.60);
            text-decoration: none;
            
          
          &.language-changer__item--active,
          &:hover {
            color: black;
          }
          }
        }
      }

    }

  }

  nav.desktop-nav {
    @include target(sm, md) {
      display: none;
      visibility: hidden;
    }

    margin-left: auto;
    height: 100%;

    .language-changer {
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
    
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      padding-right: 16px;
      font-size: 14px;
      transition: background 0.2s ease-in;
      height: 100%;
  
      &:hover {
        background: $color-grey;
  
        &::after {
          transform: rotate(180deg);
        }

        .language-changer__inner {
          pointer-events: initial;
          opacity: 1;
        }
      }
  
      &::before {
        content: attr(data-active);
        padding-left: 10px;
      }
  
      &::after {
        content: '';
        margin-left: 5px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4.5px 4.5px 0 4.5px;
        border-color: #858585 transparent transparent transparent;
        transition: transform 0.2s ease-in;
      }
    }
    
    .language-changer__inner {
      display: flex;
      flex-direction: row;
      position: absolute;
      flex-direction: column;
      background: white;
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
      left: 0;
      top: 70px;
      border-radius: 4px;
      padding: 8px 0;
      opacity: 0;
      width: 100%;
      pointer-events: none;
      transition: opacity 0.2s ease-in;
      z-index: 100;
    }
    
    .language-changer__item {
      text-decoration: none;
      color: inherit;
    
      &--active {
        color: rgba(0, 0, 0, 1);
      }
    
      padding: 8px 16px;
      font-size: 14px;
      line-height: 20px;
      transition: background 0.2s ease-in;
  
      &--active {
        display: none;
      }
  
      &:hover {
        background: $color-grey;
      }
    }
    
    .language-changer__item--form {
      display: flex;
      padding: 0;
    }

    .language-changer__item__button {
      border: none;
      background: none;
      flex: 1 1 auto;
      cursor: pointer;
      color: inherit;
      text-align: left;
      padding: 8px 16px;
      font-size: 14px;
      line-height: 20px;
    }    
  }

}