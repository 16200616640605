/*************************************
Fonts
**************************************/
@mixin font-face($font-name, $file-name, $svg: false, $weight: normal, $style: normal) { 
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    @if $svg {
      src: url($file-name + '.eot?#iefix')  format('embedded-opentype'),
           url($file-name + '.woff2') format('woff2'),
           url($file-name + '.woff') format('woff'),
           url($file-name + '.ttf')  format('truetype'),
           url($file-name + '.svg##{$font-name}')  format('svg');
    } @else {
      src: url($file-name + '.eot?#iefix')  format('embedded-opentype'),
           url($file-name + '.woff2') format('woff2'),
           url($file-name + '.woff') format('woff'),
           url($file-name + '.ttf')  format('truetype');
    }
    font-weight: $weight;
    font-style: $style;
  }
}

/*************************************
Responsive
**************************************/
@function breakpoint-next($name,
  $breakpoints: $breakpoints,
  $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min !=0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $breakpoints) {
    $next: breakpoint-next($name, $breakpoints);
    @return if($next, breakpoint-min($next, $breakpoints) - 0.02px, null);
}

@mixin target($from, $to: $from) {
    $min: breakpoint-min($from, $breakpoints);
    $max: breakpoint-max($to, $breakpoints);

    @if $min !=null and $max !=null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $max==null and $min !=null {
        @media (min-width: $min) {
            @content;
        }
    } @else if $min==null and $max !=null {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin mobile-landscape() {
    @media screen and (orientation: landscape) and (max-width: 926px) and (max-height: 428px) {
        @content;
    }
}

@mixin hide-on-target($from, $to: $from) {
    @include target($from, $to) {
        display: none;
    }
}

@mixin show-on-target($from, $to: $from, $display: block) {
    @include target($from, $to) {
        display: $display;
    }
}